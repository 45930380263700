import { StaticImage } from "gatsby-plugin-image";
import React, { useRef, useState } from "react";
import * as styles from "./carousel.module.css";

export default function Carousel({ isMobile, style }) {
    const [index, setIndex] = useState(0);
    const maskRef = useRef(null);
    return (
        <div className={styles.carouselContainer} style={style}>
            <div className={styles.carouselMask} ref={maskRef}>
                <div
                    className={styles.carousel}
                    style={{
                        transform: `translateX(${
                            (isMobile ? 0 : -index) * 440
                        }px)`,
                    }}
                >
                    {PANELS.map((p, i) => (
                        <CarouselPanel
                            key={i}
                            panel={p}
                            red={i % 2 !== 0}
                            isMobile={isMobile}
                        />
                    ))}
                </div>
            </div>
            {isMobile ? null : (
                <>
                    <div
                        onClick={() => {
                            const offset = Math.max(
                                Math.floor(maskRef.current.clientWidth / 400),
                                1
                            );
                            setIndex((i) => Math.max(i - offset, 0));
                        }}
                    >
                        <StaticImage
                            className={styles.carouselArrowLeft}
                            src="../images/carousel_arrow_left.png"
                            placeholder="tracedSVG"
                            alt="left arrow"
                            layout="fixed"
                        />
                    </div>
                    <div
                        onClick={() => {
                            const offset = Math.max(
                                Math.floor(maskRef.current.clientWidth / 400),
                                1
                            );
                            setIndex((i) =>
                                Math.min(i + offset, PANELS.length - offset)
                            );
                        }}
                    >
                        <StaticImage
                            className={styles.carouselArrowRight}
                            src="../images/carousel_arrow_right.png"
                            placeholder="tracedSVG"
                            alt="right arrow"
                            layout="fixed"
                        />
                    </div>
                </>
            )}
        </div>
    );
}

function CarouselPanel({ isMobile, panel, red }) {
    return (
        <div className={red ? styles.panelRed : styles.panelLight}>
            <div className={styles.panelImage}>{panel.icon}</div>
            <div className={styles.panelTitle}>{panel.title}</div>
            <div>{panel.description}</div>
        </div>
    );
}

const PANELS = [
    {
        icon: (
            <StaticImage
                src="../images/carousel_vatopening.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Apertura Partita Iva",
        description:
            "Bastano due click ed il tuo Commercialista specializzato Baumann aprirà la tua partita IVA",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_eletronicinvoice.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Creazione rapida Fatture Elettroniche",
        description:
            "In un secondo crei le tue fatture, direttamente dall'app. E le invii istantaneamente grazie alla tua rubrica clienti.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_invoices.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
                height={124}
            />
        ),
        title: "Gestione smart fatture attive e passive",
        description:
            "Il tuo commercialista Baumann si occuperà in tempo reale di contabilizzare tutte le fatture inserite fornendoti l'andamento aggiornato della tua attività",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_upload.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Upload on-the-go documenti fiscali",
        description:
            "Fotografa gli scontrini, carica le tue fatture di spesa e gli altri documenti fiscali in qualsiasi momento.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_dichiarazioneredditi.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Elaborazione automatica Dichiarazione dei Redditi",
        description:
            "Grazie a tutti i documenti caricati con una semplice foto, il tuo commercialista dedicato elaborerà la tua Dichiarazione dei Redditi e ti invierà il modulo già precompilato.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_f24.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Elaborazione automatica F24",
        description:
            "Il tuo commercialista Baumann ti fornirà il modello F24 già precompilato per il versamento delle imposte, che potrai pagare facilmente dall'app usufruendo del nostro servizio.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_f24installments.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Rateizzazione F24",
        description:
            "Rateizza con un click il pagamento delle imposte tramite app. Il tuo commercialista Baumann ti invierà un F24 per ogni rata.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_notifications.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Notifiche istantanee, SMS ed e-mail",
        description:
            "Il tuo commercialista Baumann ti avvisa in tempo reale su qualunque scadenza e comunicazione.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_realtime.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Monitoraggio contabilità real time",
        description:
            "Con un colpo d'occhio hai sempre sotto controllo la tua situazione contabile.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_archive.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
                height={124}
            />
        ),
        title: "Archivio intelligente",
        description:
            "Grazie al sistema di archiviazione Baumann tutti i tuoi documenti sono ben categorizzati e sempre a tua disposizione.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_intrastat.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Comunicazioni Intrastat",
        description:
            "Il tuo commercialista dedicato Baumann gestirà per te tutte le tue operazioni contabili effettuate o ricevute da o verso soggetti all'interno dell'Unione Europea.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_f24auto.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Elaborazione automatica F24 per contributi e ritenute dipendenti e fornitori.",
        description:
            "Il tuo commercialista Baumann ti fornirà il modello F24 già precompilato per il versamento dei contributi e delle ritenute, che potrai pagare facilmente dall'app usufruendo del nostro servizio.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_bollo.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Elaborazione modelli F24 per versamento imposta di bollo.",
        description:
            "Il tuo commercialista Baumann ti fornirà il modello F24 già precompilato per il versamento dell'imposta di bollo, che potrai pagare facilmente dall'app usufruendo del nostro servizio.",
    },
    {
        icon: (
            <StaticImage
                src="../images/carousel_esterometro.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
            />
        ),
        title: "Comunicazioni esterometro trimestrali",
        description:
            "Il tuo commercialista dedicato Baumann gestirà per te tutte le tue operazioni contabili effettuate o ricevute da o verso soggetti esteri.",
    },
];
