// extracted by mini-css-extract-plugin
export var grid = "grid-module--grid--ecadd";
export var gridContainer = "grid-module--gridContainer--9ba09";
export var panelColumn = "grid-module--panelColumn--26a0d";
export var panelItem = "grid-module--panelItem--0fb80";
export var panelItemButton = "grid-module--panelItemButton--a9aa9";
export var panelItemDark = "grid-module--panelItemDark--d54bc grid-module--panelItem--0fb80";
export var panelItemLight = "grid-module--panelItemLight--09a28 grid-module--panelItem--0fb80";
export var panelItemPrice = "grid-module--panelItemPrice--3cf65";
export var panelItemTitle = "grid-module--panelItemTitle--9a0f5";
export var panelTitle = "grid-module--panelTitle--73d35";
export var panelTitleTitle = "grid-module--panelTitleTitle--1f7e5";
export var panelTitleVat = "grid-module--panelTitleVat--36ded";