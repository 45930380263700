import React, { useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import * as styles from './index.module.css';
import Button from '../components/button';
import Carousel from '../components/carousel';
import Grid from '../components/grid';
import Tutorial from '../components/tutorial';
//import Reviews from '../components/reviews';
import { useIsMobile } from '../utils/hooks';
import { Helmet } from 'react-helmet';
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from '../utils/constants';

export default function IndexPage() {
    const isMobile = useIsMobile();

    const [regimeIndex, setRegimeIndex] = useState(0);

    const [showMenu, setShowMenu] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);

    const priceSectionRef = useRef(null);
    const tutorialSectionRef = useRef(null);
    const aboutSectionRef = useRef(null);
    const installSectionRef = useRef(null);

    const baseServicesRef = useRef(null);
    const priceBundlesRef = useRef(null);
    const priceServicesRef = useRef(null);
    const reviewsRef = useRef(null);

    const openMenu = () => {
        setShowMenu(true);
        setShowLogin(false);
        setShowSignup(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const openLogin = () => {
        setShowMenu(false);
        setShowLogin(true);
        setShowSignup(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const openSignup = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const goToHome = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const goToBaseServices = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        baseServicesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToPriceSection = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        priceSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToTutorialSection = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        tutorialSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToAboutSection = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToInstallSection = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        installSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToPriceBundles = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        priceBundlesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToPriceServices = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        priceServicesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToReviews = () => {
        setShowMenu(false);
        setShowLogin(false);
        setShowSignup(false);
        reviewsRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Layout
            isMobile={isMobile}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            showLogin={showLogin}
            setShowLogin={setShowLogin}
            showSignup={showSignup}
            setShowSignup={setShowSignup}
            openMenu={openMenu}
            openLogin={openLogin}
            openSignup={openSignup}
            goToHome={goToHome}
            goToPriceSection={goToPriceSection}
            goToTutorialSection={goToTutorialSection}
            goToAboutSection={goToAboutSection}
            goToInstallSection={goToInstallSection}
            goToPriceBundles={goToPriceBundles}
            goToPriceServices={goToPriceServices}
            goToReviews={goToReviews}
            goToBaseServices={goToBaseServices}
            minimal={false}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>MyBaumann</title>
                <meta name="description" content="Baumann. Il primo studio commercialista in un'app." />
                <link rel="canonical" href="https://mybaumann.it" />
            </Helmet>
            <div
                className={styles.section}
                style={{
                    backgroundColor: '#871A2D',
                    paddingTop: isMobile ? 32 : 90,
                    paddingBottom: isMobile ? 32 : 90,
                }}
            >
                <div className={styles.sectionCenter}>
                    <div
                        className={styles.sectionDescription}
                        style={{
                            color: 'white',
                            fontSize: isMobile ? 40 : 45,
                            fontWeight: 600,
                            marginBottom: 20,
                            textAlign: 'center',
                        }}
                    >
                        Un commercialista solo per te
                    </div>
                    <div
                        className={styles.sectionDescription}
                        style={{
                            color: 'white',
                            textAlign: 'center',
                            fontSize: 24,
                        }}
                    >
                        Diventa nostro cliente e ottieni l'assistenza di un commercialista a te dedicato, con cui
                        comunicare tramite app. Il rapporto personale per noi è al primo posto e, per poter seguire
                        passo passo le tue richieste, abbiamo creato l'app MyBaumann
                    </div>
                </div>
            </div>
            <div
                className={styles.section}
                style={{
                    paddingTop: isMobile ? 32 : 40,
                    paddingBottom: isMobile ? 32 : 60,
                }}
            >
                <div
                    className={styles.sectionCenter}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}
                >
                    <StaticImage
                        className={styles.servicesImage}
                        src="../images/tutti_servizi_unica_app.png"
                        alt="Servizi"
                        placeholder="tracedSVG"
                        layout="fixed"
                        width={642}
                    />
                    <div className={styles.servicesCol}>
                        <div className={styles.sectionTitle} style={{ marginBottom: 16 }}>
                            Tutti i nostri servizi in un’unica App.
                        </div>
                        <div className={styles.sectionDescription} style={{ marginBottom: 30 }}>
                            Baumann ti offre costantemente servizi esclusivi dedicati alle tue esigenze fiscali. Siamo
                            pronti a gestire la tua partita IVA in maniera efficiente ed efficace.
                        </div>
                        <Button label="Vedi Servizi" white onClick={goToBaseServices} />
                    </div>
                </div>
            </div>
            <div
                className={styles.section}
                style={{
                    backgroundColor: '#F1F1F1',
                    paddingTop: isMobile ? 32 : 100,
                    paddingBottom: isMobile ? 32 : 230,
                }}
            >
                <div className={styles.sectionCenter}>
                    <StaticImage
                        className={styles.paymentVisual}
                        src="../images/payment_visual.png"
                        alt="About"
                        placeholder="tracedSVG"
                        layout="fixed"
                        width={1295}
                    />
                    <div className={styles.paymentsCol}>
                        <div className={styles.sectionTitle} style={{ marginBottom: 20 }}>
                            Con Baumann paghi il tuo commercialista in base a quanto lo usi.
                        </div>
                        <div className={styles.sectionDescription}>
                            Scegli un abbonamento mensile, in base al tuo regime fiscale, e ottieni tutti i servizi del
                            pacchetto base dedicati alla gestione della tua partita IVA. Potrai aggiungere tanti altri
                            servizi “a consumo” ogni volta che vuoi.
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={styles.section}
                style={{
                    backgroundColor: '#871A2D',
                    paddingTop: isMobile ? 32 : 90,
                    paddingBottom: isMobile ? 32 : 50,
                }}
                ref={priceSectionRef}
            >
                <div className={styles.sectionCenter}>
                    <div
                        className={styles.sectionTitle}
                        style={{
                            color: 'white',
                            textAlign: 'center',
                            fontSize: 36,
                            marginBottom: 40,
                        }}
                        ref={priceBundlesRef}
                    >
                        Scegli il modello più adatto a te. Disdici quando vuoi.
                    </div>
                    <div className={styles.sectionRegimeRow}>
                        <div className={styles.sectionRegime}>
                            <div className={styles.regimeName}>Regime Forfettario</div>
                            <div className={styles.regimeSeparator} />
                            <div className={styles.regimePrice}>Il tuo commercialista</div>
                            <div className={styles.regimePrice} style={{ fontWeight: 600 }}>
                                a 19€ al mese
                            </div>
                            <div className={styles.regimePrice}>*iva inclusa</div>
                            <div className={styles.regimeRadio} onClick={() => setRegimeIndex(1)} aria-hidden="true">
                                <div className={styles.regimeDot} style={{ opacity: regimeIndex === 1 ? 1 : 0 }} />
                            </div>
                        </div>
                        <div className={styles.sectionRegime}>
                            <div className={styles.regimeName}>Regime Semplificato</div>
                            <div className={styles.regimeSeparator} />
                            <div className={styles.regimePrice}>Il tuo commercialista</div>
                            <div className={styles.regimePrice} style={{ fontWeight: 600 }}>
                                a 99€ al mese
                            </div>
                            <div className={styles.regimePrice}>*iva inclusa</div>
                            <div className={styles.regimeRadio} onClick={() => setRegimeIndex(2)} aria-hidden="true">
                                <div className={styles.regimeDot} style={{ opacity: regimeIndex === 2 ? 1 : 0 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={styles.section}
                style={{
                    paddingTop: isMobile ? 32 : 60,
                    paddingBottom: isMobile ? 32 : 80,
                }}
                ref={baseServicesRef}
            >
                <div className={styles.sectionCenter} style={{ maxWidth: 1292 }}>
                    <div className={styles.sectionTitle} style={{ marginBottom: isMobile ? 30 : 60 }}>
                        Servizi base inclusi
                    </div>
                    <Carousel isMobile={isMobile} style={{ marginBottom: 50 }} />
                    <Button
                        label="Installa l'APP"
                        style={{
                            fontSize: isMobile ? 40 : 45,
                            fontWeight: 600,
                            alignSelf: 'center',
                            borderRadius: 37,
                            height: 97,
                            width: isMobile ? 320 : 412,
                        }}
                        onClick={goToInstallSection}
                    />
                </div>
            </div>
            <div
                className={styles.section}
                style={{
                    paddingTop: isMobile ? 32 : 90,
                    paddingBottom: isMobile ? 32 : 100,
                }}
                ref={priceServicesRef}
            >
                <div className={styles.sectionCenter} style={{ alignItems: 'center' }}>
                    <div style={{ maxWidth: 1292 }}>
                        <div className={styles.sectionTitle} style={{ marginBottom: 12 }}>
                            Servizi a consumo
                        </div>
                        <div className={styles.sectionSubtitle} style={{ marginBottom: 80 }}>
                            Oltre alla gestione della tua partita IVA, Baumann ti permette di aggiungere, quando vuoi,
                            tutti gli altri servizi “a consumo” di cui hai bisogno.
                        </div>
                    </div>
                    <Grid isMobile={isMobile} goToInstallSection={goToInstallSection} />
                </div>
            </div>
            <div className={styles.section} style={{ backgroundColor: '#871A2D' }} ref={tutorialSectionRef}>
                <div className={styles.sectionCenter}>
                    <Tutorial isMobile={isMobile} />
                </div>
            </div>
            <div
                className={styles.section}
                style={{
                    paddingTop: isMobile ? 32 : 80,
                    paddingBottom: isMobile ? 32 : 0,
                }}
                ref={installSectionRef}
            >
                <div
                    className={styles.sectionCenter}
                    style={{
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <div className={styles.sectionAppCol}>
                        <div className={styles.sectionTitle} style={{ marginBottom: 10 }}>
                            Tutti i nostri professionisti, in una sola app.
                        </div>
                        <div className={styles.sectionDescription} style={{ marginBottom: 32 }}>
                            Installa l'app MyBaumann, siamo pronti ad adattarci alle tue esigenze fiscali.
                        </div>
                        <div className={styles.sectionStoreRow}>
                            <a href={APPLE_STORE_URL} target="_blank" rel="noreferrer">
                                <StaticImage
                                    className={styles.sectionStore}
                                    src="../images/download_apple.png"
                                    placeholder="tracedSVG"
                                    alt="Apple"
                                    layout="fixed"
                                />
                            </a>
                            <a href={GOOGLE_STORE_URL} target="_blank" rel="noreferrer">
                                <StaticImage
                                    className={styles.sectionStore}
                                    src="../images/download_android.png"
                                    placeholder="tracedSVG"
                                    alt="Android"
                                    layout="fixed"
                                />
                            </a>
                        </div>
                    </div>
                    <StaticImage
                        className={styles.storeImage}
                        src="../images/professionisti_app.png"
                        placeholder="tracedSVG"
                        alt="App"
                        layout="fixed"
                        width={625}
                    />
                </div>
            </div>
            {/* <div
                className={styles.section}
                style={{
                    backgroundColor: '#871A2D',
                    paddingTop: 36,
                    paddingBottom: 36,
                }}
            >
                <div
                    className={styles.sectionCenter}
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <StaticImage
                        className={styles.newsletterImage}
                        src="../images/newsletter.png"
                        alt="Newsletter"
                        placeholder="tracedSVG"
                        layout="fixed"
                    />
                    <div className={styles.newsletterContainer}>
                        <div
                            className={styles.sectionTitle}
                            style={{
                                color: 'white',
                                marginBottom: 12,
                            }}
                        >
                            Iscriviti alla nostra newsletter!
                        </div>
                        <div
                            className={styles.sectionDescription}
                            style={{
                                color: 'white',
                                marginBottom: 30,
                            }}
                        >
                            Ricevi tutte le ultime notizie e i tutorial, per rimanere sempre aggiornato sulle novità
                            fiscali del settore sanitario.
                        </div>
                        <div className={styles.newsletterInputContainer}>
                            <input
                                className={styles.newsletterInput}
                                type="text"
                                placeholder="Il tuo indirizzo e-mail"
                            />
                            <div className={styles.newsletterInputButton}>Iscriviti</div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className={styles.section} ref={reviewsRef}>
                <div
                    className={styles.sectionCenter}
                    style={{
                        paddingTop: isMobile ? 32 : 60,
                        paddingBottom: isMobile ? 32 : 140,
                        alignItems: 'center',
                    }}
                >
                    <div className={styles.sectionTitle} style={{ marginBottom: 10, textAlign: 'center' }}>
                        Le recensioni dei nostri clienti
                    </div>
                    <div
                        className={styles.sectionDescription}
                        style={{ marginBottom: isMobile ? 30 : 50, textAlign: 'center' }}
                    >
                        Scopri perché sempre più professionisti del mondo sanitario si affidano a noi.
                    </div>
                    <Reviews isMobile={isMobile} />
                    <Button
                        label="Installa l'APP"
                        style={{
                            fontSize: isMobile ? 40 : 45,
                            fontWeight: 600,
                            alignSelf: 'center',
                            borderRadius: 37,
                            height: 97,
                            width: isMobile ? 320 : 412,
                        }}
                        onClick={goToInstallSection}
                    />
                </div>
            </div> */}
            <div
                className={styles.section}
                style={{
                    backgroundColor: '#871A2D',
                    paddingTop: isMobile ? 32 : 100,
                    paddingBottom: isMobile ? 32 : 100,
                }}
                ref={aboutSectionRef}
            >
                <div className={styles.sectionCenter}>
                    <StaticImage
                        className={styles.aboutVisual}
                        src="../images/about_baumann.png"
                        alt="About"
                        placeholder="tracedSVG"
                        layout="fixed"
                        width={778}
                    />
                    <div className={styles.aboutCol}>
                        <div className={styles.sectionTitle} style={{ color: 'white', marginBottom: 32, fontSize: 52 }}>
                            Baumann
                        </div>
                        <div className={styles.sectionLightText} style={{ marginBottom: 32 }}>
                            Siamo commercialisti, specializzati nella gestione dei servizi contabili, fiscali e
                            finanziari dedicati ai professionisti in tutta Italia. Come tutti, siamo alle prese con un
                            periodo storico frenetico, mai uguale a sé stesso e costantemente mutevole.
                        </div>
                        <div className={styles.sectionLightText} style={{ marginBottom: 32 }}>
                            Di fronte a questa mutevolezza: che fare? Restare fermi per non essere travolti? O
                            adattarsi? Se ci state leggendo è perché abbiamo scelto la seconda opzione. È proprio
                            ispirandosi al concetto di “società liquida”, creato dal filosofo nostro omonimo, che lo
                            studio commercialista Baumann ha creato la sua App.
                        </div>
                        <div className={styles.sectionLightText}>
                            Non una app con funzioni di contabilità ma un vero e proprio studio commercialista
                            “liquido”, dedicato ai professionisti del mondo sanitario, che conosce a fondo le
                            problematiche e le difficoltà legate all'apertura e alla gestione delle partite IVA.
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
