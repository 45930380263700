import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import * as styles from "./tutorial.module.css";

const SCREENS = 4;

export default function Tutorial({ style, isMobile }) {
    const [index, setIndex] = useState(0);
    const handlers = useSwipeable({
        onSwipedLeft: () => setIndex((i) => Math.min(i + 1, SCREENS - 1)),
        onSwipedRight: () => setIndex((i) => Math.max(i - 1, 0)),
    });

    return (
        <div className={styles.tutorial} style={style} {...handlers}>
            <div className={styles.screenMask}>
                <div
                    className={styles.screenContainer}
                    style={{ transform: `translateX(${-index * 100}%)` }}
                >
                    <div className={styles.screen}>
                        <div className={styles.textContainer}>
                            <div className={styles.title}>
                                Apri la tua Partita Iva in un click.
                            </div>
                            {!isMobile && (
                                <div className={styles.description}>
                                    {DESCRIPTIONS[0]}
                                </div>
                            )}
                        </div>
                        {isMobile ? (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen1.png"
                                placeholder="blurred"
                                alt=""
                                width={320}
                            />
                        ) : (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen1.png"
                                placeholder="blurred"
                                alt=""
                                width={360}
                            />
                        )}
                        {isMobile && (
                            <div className={styles.description}>
                                {DESCRIPTIONS[0]}
                            </div>
                        )}
                    </div>
                    <div className={styles.screen}>
                        <div className={styles.textContainer}>
                            <div className={styles.title}>
                                Crea in un secondo le tue fatture.
                            </div>
                            {!isMobile && (
                                <div className={styles.description}>
                                    {DESCRIPTIONS[1]}
                                </div>
                            )}
                        </div>
                        {isMobile ? (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen2.png"
                                placeholder="blurred"
                                alt=""
                                width={320}
                            />
                        ) : (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen2.png"
                                placeholder="blurred"
                                alt=""
                                width={360}
                            />
                        )}
                        {isMobile && (
                            <div className={styles.description}>
                                {DESCRIPTIONS[1]}
                            </div>
                        )}
                    </div>
                    <div className={styles.screen}>
                        <div className={styles.textContainer}>
                            <div className={styles.title}>
                                Tutto il resto lo gestisce Baumann.
                            </div>
                            {!isMobile && (
                                <div className={styles.description}>
                                    {DESCRIPTIONS[2]}
                                </div>
                            )}
                        </div>
                        {isMobile ? (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen3.png"
                                placeholder="blurred"
                                alt=""
                                width={320}
                            />
                        ) : (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen3.png"
                                placeholder="blurred"
                                alt=""
                                width={360}
                            />
                        )}
                        {isMobile && (
                            <div className={styles.description}>
                                {DESCRIPTIONS[2]}
                            </div>
                        )}
                    </div>
                    <div className={styles.screen}>
                        <div className={styles.textContainer}>
                            <div className={styles.title}>
                                Niente più andare in Banca.
                            </div>
                            {!isMobile && (
                                <div className={styles.description}>
                                    {DESCRIPTIONS[3]}
                                </div>
                            )}
                        </div>
                        {isMobile ? (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen4.png"
                                placeholder="blurred"
                                alt=""
                                width={320}
                            />
                        ) : (
                            <StaticImage
                                className={styles.tutorialImage}
                                src="../images/tutorial_screen4.png"
                                placeholder="blurred"
                                alt=""
                                width={360}
                            />
                        )}
                        {isMobile && (
                            <div className={styles.description}>
                                {DESCRIPTIONS[3]}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.controls}>
                <div
                    onClick={() =>
                        setIndex(
                            (i) => (((i - 1) % SCREENS) + SCREENS) % SCREENS
                        )
                    }
                >
                    <StaticImage
                        className={styles.arrow}
                        src="../images/arrow_left_white.png"
                        placeholder="tracedSVG"
                        alt="left arrow"
                        layout="fixed"
                    />
                </div>
                <div className={styles.dots}>
                    <div
                        className={index === 0 ? styles.dotActive : styles.dot}
                    />
                    <div
                        className={index === 1 ? styles.dotActive : styles.dot}
                    />
                    <div
                        className={index === 2 ? styles.dotActive : styles.dot}
                    />
                    <div
                        className={index === 3 ? styles.dotActive : styles.dot}
                    />
                </div>
                <div
                    onClick={() =>
                        setIndex(
                            (i) => (((i + 1) % SCREENS) + SCREENS) % SCREENS
                        )
                    }
                >
                    <StaticImage
                        className={styles.arrow}
                        src="../images/arrow_right_white.png"
                        placeholder="tracedSVG"
                        alt="right arrow"
                        layout="fixed"
                    />
                </div>
            </div>
        </div>
    );
}

const DESCRIPTIONS = [
    "Scarica l'app e attiva il tuo account. Verrai subito assistito da un commercialista Baumann a te dedicato, che creerà la tua partita Iva.",
    "Con MyBaumann puoi creare velocemente le tue fatture tramite app e caricare quelle cartacee con una semplice foto, in qualsiasi momento.",
    "Il tuo consulente MyBaumann si occuperà di gestire tutti gli adempimenti, avvisandoti per tempo di eventuali scadenze fiscali.",
    "Non perdere tempo a pagare gli F24. Con MyBaumann puoi addebitare gli F24 direttamente sul tuo conto in banca con semplice click sull'app.",
];
