// extracted by mini-css-extract-plugin
export var arrow = "tutorial-module--arrow--2b935";
export var controls = "tutorial-module--controls--2a6bf";
export var description = "tutorial-module--description--70aa7";
export var dot = "tutorial-module--dot--a52c6";
export var dotActive = "tutorial-module--dotActive--3c309 tutorial-module--dot--a52c6";
export var dots = "tutorial-module--dots--b634d";
export var screen = "tutorial-module--screen--c5f94";
export var screenContainer = "tutorial-module--screenContainer--f5aec";
export var screenMask = "tutorial-module--screenMask--0c45f";
export var textContainer = "tutorial-module--textContainer--51676";
export var title = "tutorial-module--title--1c48d";
export var tutorial = "tutorial-module--tutorial--1a3cc";
export var tutorialImage = "tutorial-module--tutorialImage--d3363";