// extracted by mini-css-extract-plugin
export var carousel = "carousel-module--carousel--a9580";
export var carouselArrowLeft = "carousel-module--carouselArrowLeft--f888c";
export var carouselArrowRight = "carousel-module--carouselArrowRight--7d671";
export var carouselContainer = "carousel-module--carouselContainer--9cd04";
export var carouselMask = "carousel-module--carouselMask--4319b";
export var panel = "carousel-module--panel--1b076";
export var panelImage = "carousel-module--panelImage--1d2ee";
export var panelLight = "carousel-module--panelLight--2eefc carousel-module--panel--1b076";
export var panelRed = "carousel-module--panelRed--5b4cb carousel-module--panel--1b076";
export var panelTitle = "carousel-module--panelTitle--31464";