// extracted by mini-css-extract-plugin
export var aboutCol = "index-module--aboutCol--6d204";
export var aboutVisual = "index-module--aboutVisual--d5566";
export var newsletterContainer = "index-module--newsletterContainer--437d4";
export var newsletterImage = "index-module--newsletterImage--83b73";
export var newsletterInput = "index-module--newsletterInput--85ced";
export var newsletterInputButton = "index-module--newsletterInputButton--d613a";
export var newsletterInputContainer = "index-module--newsletterInputContainer--906d4";
export var paymentVisual = "index-module--paymentVisual--04820";
export var paymentsCol = "index-module--paymentsCol--6be0e";
export var phoneCardsImage = "index-module--phoneCardsImage--e5dcc";
export var regimeDot = "index-module--regimeDot--c56d9";
export var regimeName = "index-module--regimeName--5ec4b";
export var regimePrice = "index-module--regimePrice--6b325";
export var regimeRadio = "index-module--regimeRadio--6b0e0";
export var regimeSeparator = "index-module--regimeSeparator--063c6";
export var section = "index-module--section--5f2f7";
export var sectionAppCol = "index-module--sectionAppCol--8e29e";
export var sectionCenter = "index-module--sectionCenter--52e58";
export var sectionDescription = "index-module--sectionDescription--947ac";
export var sectionItalic = "index-module--sectionItalic--2b1de";
export var sectionLightText = "index-module--sectionLightText--ad495";
export var sectionRegime = "index-module--sectionRegime--889dd";
export var sectionRegimeRow = "index-module--sectionRegimeRow--1d38f";
export var sectionSmallText = "index-module--sectionSmallText--75896";
export var sectionStore = "index-module--sectionStore--07b6e";
export var sectionStoreRow = "index-module--sectionStoreRow--6e306";
export var sectionSubtitle = "index-module--sectionSubtitle--46cfd";
export var sectionTitle = "index-module--sectionTitle--32045";
export var servicesCol = "index-module--servicesCol--ef981";
export var servicesImage = "index-module--servicesImage--ecffe";
export var storeImage = "index-module--storeImage--32f18";