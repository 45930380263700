import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from './button';
import * as styles from './grid.module.css';

export default function Grid({ isMobile, style, goToInstallSection }) {
    return (
        <div className={styles.gridContainer} style={style}>
            <div className={styles.grid}>
                {(isMobile ? MOBILE_PANELS.slice(0, MOBILE_PANELS.length) : PANELS).map((p, i) =>
                    p.width === 2 ? (
                        <GridPanelTitle key={i} panel={p} />
                    ) : p.width === 1 ? (
                        !p.button ? (
                            <GridPanelItem key={i} panel={p} isMobile={isMobile} />
                        ) : (
                            <GridPanelButton
                                key={i}
                                panel={p}
                                isMobile={isMobile}
                                goToInstallSection={goToInstallSection}
                            />
                        )
                    ) : null
                )}
            </div>
        </div>
    );
}

function GridPanelTitle({ panel }) {
    return (
        <div className={styles.panelTitle}>
            <div className={styles.panelTitleTitle} style={{ maxWidth: panel.maxWidth }}>
                {panel.title}
            </div>
            {/* <div className={styles.panelTitleVat}>*IVA inclusa su tutti i servizi.</div> */}
        </div>
    );
}

function GridPanelItem({ panel, isMobile }) {
    return (
        <div className={panel.dark ? styles.panelItemDark : styles.panelItemLight}>
            <div />
            {panel.icon}
            <div className={styles.panelColumn}>
                <div className={styles.panelItemTitle}>{panel.title}</div>
                {/* <div className={styles.panelItemPrice}>
                    <span style={{ fontSize: 28, fontWeight: 700 }}>{panel.price || '...'}€</span> cad.
                </div> */}
            </div>
        </div>
    );
}

function GridPanelButton({ panel, isMobile, goToInstallSection }) {
    let style = {};
    if (isMobile) {
        style = {
            margin: '20px 0',
        };
    }
    return (
        <div className={styles.panelItemButton}>
            <Button label={panel.button} style={style} white onClick={goToInstallSection} />
        </div>
    );
}

const PANELS = [
    {
        width: 2,
        title: 'Gestione posizione previdenziale',
        maxWidth: 400,
    },
    {
        width: 1,
        icon: (
            <StaticImage src="../images/grid_cassaprof.png" placeholder="tracedSVG" alt="" layout="fixed" width={46} />
        ),
        title: 'Presentazione dichiarazione annuale cassa professionale di appartenenza',
        price: 50,
        dark: false,
    },
    {
        width: 1,
        icon: <StaticImage src="../images/grid_mav.png" placeholder="tracedSVG" alt="" layout="fixed" width={61} />,
        title: 'Elaborazione modelli MAV per perfezionamento contributi previdenziali',
        price: 50,
        dark: true,
    },
    {
        width: 1,
        icon: <StaticImage src="../images/grid_f24.png" placeholder="tracedSVG" alt="" layout="fixed" width={67} />,
        title: 'Pagamento modelli F24 tramite APP da dichiarazione dei redditi',
        price: 2,
        dark: false,
    },
    {
        width: 1,
        icon: <StaticImage src="../images/grid_f24.png" placeholder="tracedSVG" alt="" layout="fixed" width={67} />,
        title: 'Pagamento modelli F24 tramite APP per imposta di bollo trimestrale',
        price: 2,
        dark: true,
    },
    {
        width: 2,
        title: 'Gestione pagamenti tramite APP',
        maxWidth: 460,
    },
    {
        width: 2,
        title: 'Servizi integrativi',
        maxWidth: 320,
    },
    {
        width: 1,
        icon: (
            <StaticImage src="../images/grid_locazione.png" placeholder="tracedSVG" alt="" layout="fixed" width={60} />
        ),
        title: 'Registrazione contratti di locazione',
        price: 50,
        dark: true,
    },
    {
        width: 1,
        icon: (
            <StaticImage src="../images/grid_proroghe.png" placeholder="tracedSVG" alt="" layout="fixed" width={56} />
        ),
        title: 'Proroghe contratti di locazione',
        price: 50,
        dark: false,
    },
    {
        width: 1,
        icon: (
            <StaticImage src="../images/grid_cessioni.png" placeholder="tracedSVG" alt="" layout="fixed" width={63} />
        ),
        title: 'Cessioni contratti di locazione',
        price: 50,
        dark: false,
    },
    {
        width: 1,
        icon: (
            <StaticImage
                src="../images/grid_risoluzione.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
                width={49}
            />
        ),
        title: 'Risoluzione contratti di locazione',
        price: 50,
        dark: true,
    },
    {
        width: 1,
        icon: (
            <StaticImage
                src="../images/grid_successione.png"
                placeholder="tracedSVG"
                alt=""
                layout="fixed"
                width={68}
            />
        ),
        title: 'Dichiarazione di successione e volture catastali',
        price: 0,
        dark: false,
    },
    {
        width: 1,
        icon: <StaticImage src="../images/grid_imu.png" placeholder="tracedSVG" alt="" layout="fixed" width={69} />,
        title: 'Calcolo Imposta municipale unica',
        price: 15,
        dark: true,
    },
    {
        width: 1,
        icon: <StaticImage src="../images/grid_visure.png" placeholder="tracedSVG" alt="" layout="fixed" width={56} />,
        title: 'Visure camerali',
        price: 10,
        dark: true,
    },
    {
        width: 1,
        icon: (
            <StaticImage src="../images/grid_catastali.png" placeholder="tracedSVG" alt="" layout="fixed" width={62} />
        ),
        title: 'Visure catastali',
        price: 5,
        dark: false,
    },
    {
        width: 1,
        icon: (
            <StaticImage src="../images/grid_ispezioni.png" placeholder="tracedSVG" alt="" layout="fixed" width={60} />
        ),
        title: 'Ispezioni ipotecarie',
        price: 0,
        dark: true,
    },
    {
        width: 1,
        button: 'Installa App',
    },
];

const MOBILE_PANELS = PANELS.slice(0, 3)
    .concat([PANELS[5]])
    .concat(PANELS.slice(3, 5))
    .concat(PANELS.slice(6, 9).map((p) => ({ ...p, dark: !p.dark })))
    .concat(PANELS.slice(9, 13))
    .concat(PANELS.slice(13, 16).map((p) => ({ ...p, dark: !p.dark })))
    .concat(PANELS.slice(16));
